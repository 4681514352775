<template>
  <div class="mode">
    <div class="connect">
      <div class="status">
        <span>{{ $t('CONNECT_BADGE_STATUS') }}</span>
      </div>
      <s-title class="connect__title">
        <img :src="iconConnect" class="connect__icon-conect" />
        <div v-if="isMobile" @click="tooltipActive = !tooltipActive">
          <img :src="iconHelp" class="connect__icon-help" />
        </div>
      </s-title>

      <p class="subtitle">{{ $t('REPORT_CONNECT_SUBTITLE') }}</p>

      <md-tooltip
        :md-direction="isMobile ? 'bottom' : 'right'"
        :md-active="tooltipActive"
        class="tooltip"
      >
        <p class="tooltip__description">
          {{ $t('CONNECT_TOOLTIP_MESSAGE_1') }}
        </p>
        <p class="tooltip__description">
          {{ $t('CONNECT_TOOLTIP_MESSAGE_2') }}
        </p>
      </md-tooltip>

      <s-button class="eco connect__button" @click="$emit('submit')">
        {{ $t('REPORT_CONNECT_BUTTON') }}
      </s-button>

      <p class="description">
        {{ $t('CONNECT_DESCRIPTION_HELP') }}
      </p>
    </div>
  </div>
</template>
<script>
import iconConnect from '@/assets/images/connect_icon.svg'
import iconHelp from '@/assets/images/icone_help.svg'

export default {
  name: 'Connect',
  components: {
    SButton: () => import('@/components/ui/s-button'),
    STitle: () => import('@/components/ui/s-title'),
  },
  data: () => ({
    tooltipActive: false,
    iconConnect,
    iconHelp,
  }),
  computed: {
    isMobile() {
      return screen.width < 768
    },
  },
}
</script>
<style scoped>
.mode .connect .connect__title {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}
.mode .connect .connect__icon-conect {
  width: 150px;
}

.mode .connect .status {
  text-align: start;
}

.connect .connect_title {
  display: flex;
  justify-content: space-between;
}

.mode .connect .status span {
  background-color: var(--color-green-default);
  color: var(--color-white);
  font-weight: 500;
  padding: 1px 5px;
  border-radius: 20px;
  font-size: 8px;
  letter-spacing: 1.8px;
  line-height: 1.5;
  text-transform: uppercase;
}

.mode .connect .description {
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px !important;
}

.md-tooltip.md-theme-default {
  background-color: var(--color-green-light);
  color: var(--color-green-default);
}
</style>
